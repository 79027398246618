import React from 'react';

export const SERBIAN_MONTHS = [
    {
        "id": 1,
        "title": "Januar"
    },
    {
        "id": 2,
        "title": "Februar"
    },
    {
        "id": 3,
        "title": "Mart"
    },
    {
        "id": 4,
        "title": "April"
    },
    {
        "id": 5,
        "title": "Maj"
    },
    {
        "id": 6,
        "title": "Jun"
    },
    {
        "id": 7,
        "title": "Jul"
    },
    {
        "id": 8,
        "title": "Avgust"
    },
    {
        "id": 9,
        "title": "Septembar"
    },
    {
        "id": 10,
        "title": "Oktobar"
    },
    {
        "id": 11,
        "title": "Novembar"
    },
    {
        "id": 12,
        "title": "Decembar"
    }
];


export function formatDate(date_string) {
    var timestamp = Date.parse(date_string);
    let m = new Date(timestamp);
    return (
        ("0" + m.getDate()).slice(-2) + "-" +
        ("0" + (m.getMonth() + 1)).slice(-2) + "-" +
        m.getFullYear() + " " +
        ("0" + m.getHours()).slice(-2) + ":" +
        ("0" + m.getMinutes()).slice(-2) 
    );
}

export function addHours(date, hours) {
    date.setTime(date.getTime() + hours * 60 * 60 * 1000);
  
    return date;
}


export function addMonths(date, months) {
    date.setMonth(date.getMonth() + months);
  
    return date;
}


/**
 * 
 * @param {Date} date 
 * @param {int} days 
 * @returns 
 */
export function addDays(date, days) {
    date.setDate(date.getDate() + days);  
    return date;
}
  
/**
 * Razlika u minutima
 * @param {string} dateString 
 * @returns int
 */
export function getMinutesDifference(dateString) {
    try {
        // Parse the input date string into a Date object
        const inputDate = new Date(dateString);
    
        // Get the current date and time
        const currentDate = new Date();
    
        // Calculate the difference in milliseconds
        const differenceInMillis = currentDate - inputDate;
    
        // Convert milliseconds to minutes
        const differenceInMinutes = Math.floor(differenceInMillis / (1000 * 60));
    
        return differenceInMinutes;        
    } catch (error) {
        console.log("getMinutesDifference", error);
        return 0;
    }

}  

/**
 * 
 * @param {int} minutes 
 * @returns string
 */
export function formatMinutes(minutes) {
    if (isNaN(minutes) || minutes < 0) {
      return '';
    }
  
    const days = Math.floor(minutes / (24 * 60));
    const hours = Math.floor((minutes % (24 * 60)) / 60);
    const remainingMinutes = minutes % 60;
  
    let result = '';
  
    if (days > 0) {
      result += `${days}d `;
    }
  
    if (hours > 0) {
      result += `${hours}h `;
    }
  
    if (remainingMinutes > 0 || result === '') {
      result += `${remainingMinutes}min`;
    }
  
    return result.trim();
}


export function formatDateGraph(date_string){
    var timestamp = Date.parse(date_string);
    let m = new Date(timestamp);
    return (
        ("0" + m.getDate()).slice(-2) + "-" +
        ("0" + (m.getMonth() + 1)).slice(-2) + " " +
        ("0" + m.getHours()).slice(-2) + ":" +
        ("0" + m.getMinutes()).slice(-2) 
    );
}
