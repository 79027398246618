import React, { useState, useEffect, useRef } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import { Tag } from 'primereact/tag';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';


import { StatisticsService } from '../../service/StatisticsService';
import { addMonths } from '../../helper/DateHelper';
import { MeasurementService } from '../../service/MeasuremetService';

const SensorSummaryMonthly = () => {

    let emptyRow = {
        'id': null,
        'name': ""
    };

    const [gridData, setGridData] = useState(null);
    const [gridDialog, setGridDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [selectedRow, setSelectedRow] = useState(emptyRow);
    const [multiSortMeta, setMultiSortMeta] = useState([{ field: 'id', order: 1 }]);

    const toast = useRef(null);
    const dt = useRef(null);

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(1000);
    const [firstPage, setFirstPage] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(null);
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const [startDate, setStartDate] = useState(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1));
    const [endDate, setEndDate] = useState(new Date());

    const [summaryData, setSummaryData] = useState(null);
    const [histogramData, setHistogramData] = useState(null);
    

    const initFilters = {
        'terminalcode': { value: 3, matchMode: FilterMatchMode.CONTAINS },
        'month': { value: startDate.getMonth()+1, matchMode: FilterMatchMode.EQUALS },
        'year': { value: startDate.getFullYear(), matchMode: FilterMatchMode.EQUALS },
        //'value': { value: -1, matchMode: FilterMatchMode.GREATER_THAN },
        'value': { operator: FilterOperator.AND, constraints: [
            { value: 0, matchMode: FilterMatchMode.GREATER_THAN }, 
            { value: 3, matchMode: FilterMatchMode.LESS_THAN }
        ]},
        'timestamp'  : { operator: FilterOperator.AND, constraints: [
            { value: startDate, matchMode: FilterMatchMode.DATE_AFTER }, 
            { value: endDate, matchMode: FilterMatchMode.DATE_BEFORE }
        ]}
    }

    const paginatorLeft = "";
    const paginatorRight = "";

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: pageSize,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: initFilters
    });

    useEffect(() => {
        loadLazyData();
        loadSummaryData();
    }, [lazyParams]);


    useEffect(() => {
        gridData && createHistogramData(0.01);
    }, [gridData]);

    const createHistogramData = (binSize) => {
        const minValue = Math.min(...gridData?.map(d => d.value));
        const maxValue = Math.max(...gridData?.map(d => d.value));

        console.log("Min Max", minValue, maxValue);
        
        const bins = [];
        for (let i = minValue; i <= maxValue; i += binSize) {
            bins.push({ bin: i.toFixed(2), count: 0 });
        }

        gridData?.forEach(d => {
            const binIndex = Math.floor((d.value - minValue) / binSize);
            bins[binIndex].count += 1;
        });

        //return bins;
        setHistogramData(bins);
    };

    const loadLazyData = ()  => {
        setLoading(true);
        const measurementsService = new MeasurementService();
        measurementsService.getMeasurementV2(lazyParams.page + 1, lazyParams.rows, lazyParams.filters, 
            { field: lazyParams.sortField, order: lazyParams.sortOrder }).then(data => {
                if("status" in data && data.status === 401) {
                    //props.logout();
                }
                //console.log("Statistics list", data);
                setGridData(data);
                setLoading(false);
        }).catch(error => {
            console.log("Sensor summary error:", error);
        });
    }

    const loadSummaryData = ()  => {
        setLoading(true);
        const statisticsService = new StatisticsService();
        statisticsService.getProcessCapabilitySummary(lazyParams.page + 1, lazyParams.rows, lazyParams.filters, 
            { field: lazyParams.sortField, order: lazyParams.sortOrder }).then(data => {
                if("status" in data && data.status === 401) {
                    //props.logout();
                }
                //console.log("Statistics list", data);
                setSummaryData(data.data);
                setLoading(false);
        }).catch(error => {
            console.log("Sensor summary error:", error);
        });
    }

    const setFilterByStartDate = (datePostedValue) => {        
        try {
            setStartDate(datePostedValue);
            //console.log(datePostedValue);

            const year = datePostedValue.getFullYear();
            const firstDay = new Date(year, datePostedValue.getMonth(), 1);
            const lastDay = new Date(year, datePostedValue.getMonth() + 1, 0);

            const _params = JSON.parse(JSON.stringify(lazyParams));

            const result = _params.filters.timestamp.constraints.map(el => el.matchMode === FilterMatchMode.DATE_AFTER 
                ? {...el, value:firstDay} : el);
            
            _params.filters.timestamp.constraints = result;

            const result2 = _params.filters.timestamp.constraints.map(el => el.matchMode === FilterMatchMode.DATE_BEFORE 
                ? {...el, value:lastDay} : el);
            
            _params.filters.timestamp.constraints = result2;                

            _params.filters.month.value = datePostedValue.getMonth() + 1;
            _params.filters.month.year = datePostedValue.getFullYear();

            setLazyParams(_params);
        } catch (error) {
            console.log("Error setFilterByStartDate", error);
        }
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="field">
                    <label htmlFor="range1" className="block">Mesec: </label>
                            <Calendar value={startDate} onChange={(e) => setFilterByStartDate(e.value)}  view="month" dateFormat="mm/yy"/>&nbsp;&nbsp;
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <span>
                    {/* <Button label="Export" onClick={() => exportExcel(gridData)} />&nbsp;&nbsp;
                    <Button label="Export all" onClick={() => exportCompleteResults(gridData)} />  */}
                </span>
            </React.Fragment>
        )
    }


    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Lista merenja</h5>
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <Toast ref={toast} />
                    <div className="card">
                        <div className="grid">
                            <div className="col-4">
                                <div style={{ fontSize: '1.1rem', padding: 10, }}>Prosečna vrednost (AVG): &nbsp;&nbsp;
                                    <Tag value={summaryData && summaryData[0]?.avg} style={{ fontSize: '1.1rem' }}/>
                                </div>
                                <div style={{ fontSize: '1.1rem', padding: 10, }}>Standardna devijacija (STD): &nbsp;&nbsp;
                                    <Tag value={summaryData && summaryData[0]?.std} style={{ fontSize: '1.1rem' }}/>
                                </div>
                            </div>
                            <div className="col-8">
                                <div style={{ fontSize: '1.1rem', padding: 10, }}>Indeks sposobnosti CP (veće od 1): &nbsp;&nbsp;                                    
                                    {
                                        summaryData && summaryData[0]?.cp < 1 && 
                                        <Tag value={summaryData && summaryData[0]?.cp} severity={"danger"} style={{ fontSize: '1.1rem' }}/>
                                    }
                                    {
                                        summaryData && summaryData[0]?.cp > 1 && 
                                        <Tag value={summaryData && summaryData[0]?.cp} severity={"success"} style={{ fontSize: '1.1rem' }}/>
                                    }

                                </div>
                                <div style={{ fontSize: '1.1rem', padding: 10, }}>Indeks prilagođen za centriranje CPK (veće od 1, poželjno veće od 1.33): &nbsp;&nbsp;
                                    {
                                        summaryData && summaryData[0]?.cpk < 1 && 
                                        <Tag value={summaryData && summaryData[0]?.cpk} severity={"danger"} style={{ fontSize: '1.1rem' }}/>
                                    }
                                    {
                                        summaryData && summaryData[0]?.cpk > 1 && summaryData && summaryData[0]?.cpk < 1.33 && 
                                        <Tag value={summaryData && summaryData[0]?.cpk} severity={"warning"} style={{ fontSize: '1.1rem' }}/>
                                    }
                                    {
                                        summaryData && summaryData[0]?.cpk > 1.33 && 
                                        <Tag value={summaryData && summaryData[0]?.cpk} severity={"success"} style={{ fontSize: '1.1rem' }}/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <ResponsiveContainer width="100%" height={600}>
                    <BarChart data={histogramData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="bin" label={{ value: 'Value', position: 'insideBottomRight', offset: 0 }} />
                        <YAxis label={{ value: 'Count', angle: -90, position: 'insideLeft' }} />
                        <Tooltip />
                        <Bar dataKey="count" fill="#8884d8" />
                    </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};


export default React.memo(SensorSummaryMonthly, comparisonFn);