
export function filterDataByTerminalLimits(data, terminal) {
    const ignoreLimits = terminal.ignorelimits;
    if (ignoreLimits && typeof ignoreLimits.low === 'number' && typeof ignoreLimits.high === 'number') {
        const { low, high } = ignoreLimits;
        return data.filter(item => 
            item.terminalcode === terminal.terminalcode && 
            item.value > low && 
            item.value < high
        );
    } else {
        console.error('Invalid ignorelimits in terminal object');
        return data;
    }
}