
export const onInputChange = (e, name, stateProperty, setStateProperty) => {        
    const val = (e.target && e.target.value) || '';
    let _row = { ...stateProperty };
    _row[`${name}`] = val;

    setStateProperty(_row);
}

export const onCheckboxChange = (e, name, stateProperty, setStateProperty) => {
    let _row = { ...stateProperty };
    _row[`${name}`] = e.target.checked;

    setStateProperty(_row);
}

export const onSelectChange = (e, name, stateProperty, setStateProperty) => {
    let _row = { ...stateProperty };
    _row[`${name}`] = e.target.value;
    setStateProperty(_row);

}
/* export const onLimitsChange = (e, limit, type, stateProperty, setStateProperty) => {        
    const val = (e.target && e.target.value) || '';
    let _row = { ...stateProperty };
    _row[`${limit}`][`${type}`] = val;

    setStateProperty(_row);
} */