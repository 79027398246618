import React, { useState, useEffect, useRef } from 'react';
import { SensorItem } from '../../components/SensorItem';
import { TerminalsService } from '../../service/TerminalService';


const SensorStatus = () => {

    const [gridData, setGridData] = useState(null);
    const [gridDialog, setGridDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const [loading, setLoading] = useState(false);
    const hideDialog = () => {
        setSubmitted(false);
        setGridDialog(false);
    }


    useEffect(() => {
        setLoading(true);
        const terminalService = new TerminalsService();
        terminalService.getTerminalsList().then(data => {
            setGridData(data);
            setLoading(false);
        })
    }, []);


    return (
        <div className="grid">
            <div className="col-6">
                <div className="card">
                    <h5>Statusi senzora 1</h5>
                    <div className="grid p-fluid mt-1">
                        {
                            gridData?.map((element, index) => {
                                return <SensorItem id={element.terminalcode} name={element.name} isactive={element.isactive} />
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};


export default React.memo(SensorStatus, comparisonFn);