
    export const findIndexById = (id, collection) => {
        let index = -1;
        for (let i = 0; i < collection.length; i++) {
            if (collection[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    export const findIndexByIdV2 = (id, property, collection) => {
        let index = -1;
        for (let i = 0; i < collection.length; i++) {
            if (collection[i][property] === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    export const getMaxId = (property, collection) => {
        if (collection.length > 0) {
            return Math.max(...collection.map(item => item[property])) + 1;
        }
        return 1;
    }


    export const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 24; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    export const  generate_uuidv4 = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
        function(c) {
           var uuid = Math.random() * 16 | 0, result = c === 'x' ? uuid : ((uuid & 0x3) | 0x8);
           return uuid.toString(16);
        });
     }
    