import { SET_USER_KEY } from '../constants/types';

const INITIAL_STATE = {
  current: {
    "user": null,
    "token": null,
    "expires_in": null
  },
};

export const userKeyReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SET_USER_KEY:

            return {
                ...state,
                current: action.payload
            };
        default:
            return state;
    }
}

