import React, { useState, useEffect, useRef } from 'react';

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Calendar } from 'primereact/calendar';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { addMonths } from '../../helper/DateHelper';
import { MeasurementService } from '../../service/MeasuremetService';
import { filterListTemplate } from '../../helper/FilterHelper';
import { listBodyTemplate } from '../../components/DataColumnTemplate';
import { exportExcel } from '../../helper/FileHelper';
import { TerminalsService } from '../../service/TerminalService';

const MeasurementsList = () => {

    let emptyRow = {
        'id': null,
        'name': ""
    };
    const [gridData, setGridData] = useState(null);
    const [terminalGridData, setTerminalGridData] = useState(null);
    const [gridDialog, setGridDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [selectedRow, setSelectedRow] = useState(emptyRow);
    const [multiSortMeta, setMultiSortMeta] = useState([{ field: 'id', order: 1 }]);

    const toast = useRef(null);
    const dt = useRef(null);

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [firstPage, setFirstPage] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(null);
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const [startDate, setStartDate] = useState(addMonths(new Date(), -1));
    const [endDate, setEndDate] = useState(new Date());

    const initFilters = {
        'terminalcode': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'value': { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
        'timestamp'  : { operator: FilterOperator.AND, constraints: [
            { value: startDate, matchMode: FilterMatchMode.DATE_AFTER }, 
            { value: endDate, matchMode: FilterMatchMode.DATE_BEFORE }
        ]} 
    }

    const paginatorLeft = "";
    const paginatorRight = "";

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: pageSize,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: initFilters
    });

    useEffect(() => {
        setLoading(true);
        const terminalService = new TerminalsService();
        terminalService.getTerminalsList().then(data => {
            setTerminalGridData(data);
            setLoading(false);
        })
    }, []);

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = ()  => {
        setLoading(true);
        const measurementService = new MeasurementService();
        measurementService.getMeasurement(lazyParams.page + 1, lazyParams.rows, lazyParams.filters, 
            { field: lazyParams.sortField, order: lazyParams.sortOrder }).then(data => {
                if("status" in data && data.status === 401) {
                    //props.logout();
                }
                //console.log("Measurement list", data);
                setGridData(data.data);
                setPageNumber(data.pageNumber);
                setPageSize(data.pageSize);
                setFirstPage(data.firstPage);
                setLastPage(data.lastPage);
                setTotalPages(data.totalPages);
                setTotalRecords(data.totalRecords);
                setNextPage(data.nextPage);
                setPreviousPage(data.previousPage);
                setLoading(false);
        }).catch(error => {
            console.log("Measurement error:", error);
        });
    }    

    const onPage = (event) => {
        setLazyParams(event);
    }

    const onSort = (event) => {
        event['first'] = 0;
        event['page'] = 0;
        event['rows'] = pageSize;
        if(event["multiSortMeta"].length > 0) {
            event['sortField'] = event["multiSortMeta"][0].field;
            event['sortOrder'] = event["multiSortMeta"][0].order;
        }
        else {
            event['sortField'] = null;
            event['sortOrder'] = null;
        }
        setMultiSortMeta(event["multiSortMeta"]);
        setLazyParams(event);
    }

    const onFilter = (event) => {
        console.log("OnFilter", event);
        event['first'] = 0;
        event['page'] = 0;
        event['rows'] = pageSize;
        setLazyParams(event);
    }

    const setFilterByStartDate = (datePostedValue) => {        
        try {
            setStartDate(datePostedValue);
            const _params = JSON.parse(JSON.stringify(lazyParams));
            const result = _params.filters.timestamp.constraints.map(el => el.matchMode === FilterMatchMode.DATE_AFTER 
                ? {...el, value:datePostedValue} : el);
            _params.filters.timestamp.constraints = result;
            setLazyParams(_params);
        } catch (error) {
            console.log("Error setFilterByStartDate", error);
        }
    }

    const setFilterByEndDate = (datePostedValue) => {
        try {
            setEndDate(datePostedValue);
            const _params = JSON.parse(JSON.stringify(lazyParams));
            const result = _params.filters.timestamp.constraints.map(el => el.matchMode === FilterMatchMode.DATE_BEFORE 
                ? {...el, value:datePostedValue} : el);
            _params.filters.timestamp.constraints = result;
            setLazyParams(_params);
        } catch (error) {
            console.log("Error setFilterByEndDate", error);
        }
    }

    const exportCompleteResults = () => {
        setLoading(true);
        const measurementService = new MeasurementService();
        //console.log(1, totalPages, lazyParams.rows);
        measurementService.exportMeasurements(1, totalPages, lazyParams.rows, lazyParams.filters, 
            { field: lazyParams.sortField, order: lazyParams.sortOrder }).then(data => {
                if("status" in data && data.status === 401) {
                    //props.logout();
                }
                console.log("Data ", data)
                exportExcel(data);
                setLoading(false);
        }).catch(error => {
            console.log("ExportCompleteResults error:", error);
        });
    }

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">

            </div>
        )
    }

    const gridDialogFooter = (
        <>

        </>
    );

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="field">
                    <label htmlFor="range1" className="block">Od datuma: </label>
                            <Calendar value={startDate} onChange={(e) => setFilterByStartDate(e.value)} dateFormat="dd.mm.yy"/>&nbsp;&nbsp;
                </div>
                <div className="field">
                    <label htmlFor="range1" className="block">Do datuma: </label>
                        <Calendar value={endDate} onChange={(e) => setFilterByEndDate(e.value)} dateFormat="dd.mm.yy"/>&nbsp;&nbsp;
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <span>
                    <Button label="Export" onClick={() => exportExcel(gridData)} />&nbsp;&nbsp;
                    <Button label="Export all" onClick={() => exportCompleteResults(gridData)} /> 
                </span>
            </React.Fragment>
        )
    }

    const header = renderHeader();

    return (
        <div className="grid">
            <div className="col-9">
                <div className="card">
                    <h5>Lista merenja</h5>
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <Toast ref={toast} />                    
                    <DataTable
                        ref={dt}
                        lazy
                        value={gridData}
                        sortMode="multiple"
                        removableSort
                        multiSortMeta={multiSortMeta}
                        filterDisplay="row"
                        onFilter={onFilter} 
                        filters={lazyParams.filters}
                        first={lazyParams.first}                        
                        onPage={onPage}
                        onSort={onSort} 
                        sortField={lazyParams.sortField} 
                        sortOrder={lazyParams.sortOrder}
                        globalFilterFields={['id', 'name']}
                        header={header}
                        loading={loading}
                        selectionMode="single"
                        selection={selectedRow}
                        onSelectionChange={e => setSelectedRow(e.value)}
                        paginator
                        paginatorLeft={paginatorLeft}
                        paginatorRight={paginatorRight}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Prikaz {first} do {last} od ukupno {totalRecords} merenja"
                        rows={pageSize} 
                        rowsPerPageOptions={[10, 25, 50, 100, 200]}
                        totalRecords={totalRecords}  
                        emptyMessage="No data found."  
                        responsiveLayout="scroll"                       
                    >
                        {/*<Column field="id" header="ID" filter filterPlaceholder="Pretraži po id" style={{ width: '5%',minWidth: '12rem' }} sortable></Column>*/}
                        <Column field="terminalcode" header="Senzor" sortable headerStyle={{  minWidth: '8rem' }} 
                            body={(rowData, property) => listBodyTemplate(rowData, property, terminalGridData, 'terminalcode', 'name')}
                            filter
                            filterElement={(options) => filterListTemplate(options, terminalGridData, 'terminalcode', 'name')} ></Column>
                        <Column field="value" header="Vrednost" filter sortable></Column> 
                        {/*<Column field="value" header="Rezultat" body={measurementBodyTemplate} filter sortable></Column>*/}
                        <Column field="timestamp" header="Vreme" sortable></Column>  
                    </DataTable>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};


export default React.memo(MeasurementsList, comparisonFn);