
import { Dropdown } from 'primereact/dropdown';

export const filterListTemplate = (options, list, value, label) => {
    return (
        <Dropdown value={options.value} options={list} optionLabel={label} optionValue={value} onChange={(e) => {
            options.filterApplyCallback(e.value);
        } }  
        placeholder="..." className="p-column-filter" showClear />
    );
}