import "../styles.css";
import { useState, useEffect } from "react";


//Prime Imports
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { UsersService } from "../service/UsersService";

export default function Login({ setLoggedInState, setState }) {
  const [loading, setLoading] = useState(false);
  const [Error, setError] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [trackLogin, setTrackLogin] = useState("notstarted");
  

  const onLoginBtnClicked = () => {

    let userService = new UsersService();


    userService.loginUser(username, password).then(data => {    

        localStorage.setItem('user_name', username);
        localStorage.setItem('token', data.token);
        localStorage.setItem('expires_in', data.expires_in);
        
    })

    setTrackLogin("started");
    setLoading(true);
    if (!username || !password) {
      setTrackLogin("failed");
      setError(true);
      setTimeout(() => {
        setError(false);
        setLoading(false);
      }, 3000);
    } else {
      setTimeout(() => {
        setLoading(false);
        setTrackLogin("completed");
        setState({ loggedInUser: username, AppData: "" });
      }, 2000);
    }
  };



  useEffect(() => {
    if (trackLogin === "failed") {
      setLoggedInState(false);
    } else if (trackLogin === "completed") {
      setLoggedInState(true);
    }
  }, [trackLogin, setLoggedInState]);

  return (
    <div className="login-container">
      <Card
        title="Elitinox"
        className="login-card p-shadow-24"
        style={{ width: "55rem", marginBottom: "2em", fontSize:"large" }}
      >
        <div className="p-fluid">
          <div className="p-field">
            <InputText
              id="username"
              type="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              aria-describedby="login-help"
              placeholder="Korisničko ime"
              style={{ fontSize:"large" }}
            />
          </div>
          <div className="login-form-between-padding"></div>
          <div className="p-field">
            <InputText
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              aria-describedby="login-help"
              placeholder="Šifra"
              style={{ fontSize:"large" }}
            />
            {Error ? (
              <small id="login-help">Username or Password is incorrect!</small>
            ) : null}
          </div>
          <div className="login-form-between-padding"></div>
          <div className="p-field">
            <Button
                label="Login"
                loading={loading}
                onClick={() => onLoginBtnClicked()}
                style={{ fontSize:"large" }}
                />
          </div>
        </div>            
      </Card>
    </div>
  );
}
