import axios from 'axios';

export class UsersService {

    async getUsersList() {
        let token = localStorage.getItem("token");
        const result = await axios.get('/users/list', {
            headers: { 
                "Authorization": "Bearer " + token
            }            
        } 
        ).then(res =>
            (res.data === null ? [{}] : res.data)
        ).catch(error => {

            if(error.response.status === 401) {
                console.log(error.response);
                localStorage.removeItem('user_name');
                localStorage.removeItem('token');
                localStorage.removeItem('expires_in');
            }
        });
        return result;
    }

    async loginUser(username, password) {
        var bodyFormData = new FormData();
        bodyFormData.append('Username', username);
        bodyFormData.append('Password', password);

        const result = await axios.post('/api/account/login', bodyFormData,  {
            headers: { "Content-Type": "multipart/form-data" },            
        }
        ).then(res =>
            (res.data === null ? [{}] : res.data)
        );
        return result;
    }

    async createUser(data) {


        const result = await axios.post('/users', data).then(res => {
            return {
                status: (res.status === 201),
                data: (res.data === null ? [{}] : res.data)
            }
        }
        );
        return result;

    }

    async updateUser(data) {
        const result = await axios.put('/users', data).then(res => {
            return (res.status === 204)
        });
        return result;
    }

}