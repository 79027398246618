import React, { useState, useEffect, useRef } from 'react';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { formatDate, SERBIAN_MONTHS } from '../helper/DateHelper';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { StatisticsService } from '../service/StatisticsService';
import { set } from 'date-fns';


const Dashboard = (props) => {
    const [workOrders, setWorkOrders] = useState(null);
    const [measurementsTotal, setMeasurementsTotal] = useState(0);
    const [measurementsBad, setMeasurementsBad] = useState(0);
    const [measurementsGoodPercent, setMeasurementsGoodPercent] = useState(0);
    const [measurementsCPKAverage, setMeasurementsCPKAverage] = useState(0);
    
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [firstPage, setFirstPage] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(1);
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const menu1 = useRef(null);
    const menu2 = useRef(null);
    const [lineOptions, setLineOptions] = useState(null);
    const [summaryData, setSummaryData] = useState(null);
    
    const [cpkSummaryData, setCpkSummaryData] = useState(null);
    const [totalMeasurementData, setTotalMeasurementData] = useState(null);
    const [badMeasurementData, setBadMeasurementData] = useState(null);
    const [badPercentMeasurementData, setBadPercentMeasurementData] = useState(null);



    const initFilters = {
        'anQId': { value: null, matchMode: FilterMatchMode.EQUALS },
        'acKey': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'acName': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'acStatus': { value: '', matchMode: FilterMatchMode.CONTAINS },
        'adDate'  : { operator: FilterOperator.AND, constraints: [
            { value: null, matchMode: FilterMatchMode.DATE_AFTER }, 
            { value: null, matchMode: FilterMatchMode.DATE_BEFORE }
        ]} 
    }

    const loadSummaryData = ()  => {
        setLoading(true);
        const statisticsService = new StatisticsService();
        statisticsService.getProcessCapabilitySummary(lazyParams.page + 1, lazyParams.rows, lazyParams.filters, 
            { field: lazyParams.sortField, order: lazyParams.sortOrder }).then(data => {
                if("status" in data && data.status === 401) {
                    //props.logout();
                }
                setSummaryData(data.data);
                setLoading(false);
        }).catch(error => {
            console.log("Sensor summary error:", error);
        });
    }

    const loadCpkSummaryData = ()  => { 
        const labels = summaryData?.map(item => `${SERBIAN_MONTHS[item.month - 1]?.title} ${item.year}`)?.reverse();
        const cpkData = summaryData?.map(item => item.cpk)?.reverse();
    
        const destination = {
            labels: labels, // Serbian month names + year
            datasets: [
                {
                    label: 'Cpk prosek',
                    data: cpkData, // cpk values
                    fill: false,
                    backgroundColor: '#cc00ff',
                    borderColor: '#cc00ff',
                    tension: 0.4
                }
            ]
        };
        setCpkSummaryData(destination);
        setMeasurementsCPKAverage(summaryData?.reduce((acc, item) => acc + item.cpk, 0) / summaryData?.length);
    }

    const loadTotalMeasurementData = () => {
        const labels = summaryData?.map(item => `${SERBIAN_MONTHS[item.month - 1]?.title} ${item.year}`)?.reverse();
        const totalData = summaryData?.map(item => item.total)?.reverse();
        const badData = summaryData?.map(item => (item.total - item.insidelcltotal))?.reverse();

        const destination = {
            labels: labels,
            datasets: [
                {
                    label: 'Ukupno merenja',
                    data: totalData,
                    fill: false,
                    backgroundColor: '#2f4860',
                    borderColor: '#2f4860',
                    tension: .4
                },
                {
                    label: 'Loša merenja',
                    data: badData,
                    fill: false,
                    backgroundColor: '#00bb7e',
                    borderColor: '#00bb7e',
                    tension: .4
                }
            ]
        };

        const destinationBadPercent = {
            labels: labels,
            datasets: [
                {   
                    label: 'Pozicije van opsega %',
                    data: summaryData?.map(item => (item.total - item.insidelcltotal) / item.total * 100)?.reverse(),
                    fill: false,
                    backgroundColor: '#3385ff',
                    borderColor: '#3385ff',
                    tension: .4
                }
            ]
        };

        const destinationBad = {
            labels: labels,
            datasets: [
                {   
                    label: 'Pozicije van opsega',
                    data: summaryData?.map(item => (item.total - item.insidelcltotal))?.reverse(),
                    fill: false,
                    backgroundColor: '#ff4d4d',
                    borderColor: '#ff4d4d',
                    tension: .4
                }
            ]
        };

        setTotalMeasurementData(destination);
        setBadPercentMeasurementData(destinationBadPercent);
        setBadMeasurementData(destinationBad);
        setMeasurementsTotal(summaryData?.reduce((acc, item) => acc + item.total, 0));
        setMeasurementsBad(summaryData?.reduce((acc, item) => acc + (item.total - item.insidelcltotal), 0));
        setMeasurementsGoodPercent((summaryData?.reduce((acc, item) => acc + item.insidelcltotal, 0) / summaryData?.reduce((acc, item) => acc + item.total, 0) * 100).toFixed(2));
    }

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: pageSize,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: initFilters
    });    


    const applyLightTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
            }
        };

        setLineOptions(lineOptions)
    }

    const applyDarkTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#ebedef'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                },
                y: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                },
            }
        };

        setLineOptions(lineOptions)
    }


    useEffect(() => {
        if (props.colorMode === 'light') {
            applyLightTheme();
        } else {
            applyDarkTheme();
        }
    }, [props.colorMode]);


    useEffect(() => {
        loadSummaryData();
    }, [lazyParams]);

    
    useEffect(() => {
        loadCpkSummaryData();
    }, [summaryData]);

    useEffect(() => {
        loadTotalMeasurementData();
    }, [summaryData]);


    const dateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Category</span>
                {formatDate(rowData.adDate)}
            </>
        );
    }

    return (
        <div className="grid">
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Broj merenja</span>
                            <div className="text-900 font-medium text-xl">{measurementsTotal}</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-circle-on text-blue-500 text-xl"/>
                        </div>
                    </div>
                    {/* <span className="text-green-500 font-medium">0 nova </span>
                    <span className="text-500">radna naloga</span> */}
                    </div>
                </div>
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Loša merenja</span>
                            <div className="text-900 font-medium text-xl">{measurementsBad}</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-clock text-orange-500 text-xl"/>
                        </div>
                    </div>
                    {/* <span className="text-green-500 font-medium">%0 </span>
                    <span className="text-500">prosečno kompletirano</span> */}
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Merenja u opsegu %</span>
                            <div className="text-900 font-medium text-xl">{measurementsGoodPercent}</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-check text-cyan-500 text-xl"/>
                        </div>
                    </div>
                    {/* <span className="text-green-500 font-medium">0  </span>
                    <span className="text-500">operacija u toku</span> */}
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">CPK</span>
                            <div className="text-900 font-medium text-xl">{measurementsCPKAverage}</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-box text-purple-500 text-xl"/>
                        </div>
                    </div>
                    {/* <span className="text-green-500 font-medium">0 </span>
                    <span className="text-500">radnih naloga urađeno</span> */}
                </div>
            </div>

            <div className="col-12 xl:col-6">
                <div className="card">
                    <h5>Procenat pozicija van opsega %:</h5>
                    <Chart type="bar" data={badPercentMeasurementData} />
                </div>
                <div className="card">
                    <div className="flex justify-content-between align-items-center mb-5">
                        <h5>Broj pozicija van opsega</h5>
                        <div>
                            <Button type="button" icon="pi pi-ellipsis-v" className="p-button-rounded p-button-text p-button-plain" onClick={(event) => menu1.current.toggle(event)}/>
                            <Menu ref={menu1} popup model={[{ label: 'Add New', icon: 'pi pi-fw pi-plus' }, { label: 'Remove', icon: 'pi pi-fw pi-minus' }]}/>
                        </div>

                    </div>
                    <Chart type="bar" data={badMeasurementData} />
                </div>
            </div>

            <div className="col-12 xl:col-6">
                <div className="card">
                    <h5>Broj merenja</h5>
                    <Chart type="bar" data={totalMeasurementData} options={lineOptions} />
                </div>

                <div className="card">
                    <h5>CPK</h5>
                    {
                        cpkSummaryData && (
                            <Chart type="line" data={cpkSummaryData} options={lineOptions} />
                        )
                    }                    
                </div>

            </div>


        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return (prevProps.location.pathname === nextProps.location.pathname) && (prevProps.colorMode === nextProps.colorMode);
};

export default React.memo(Dashboard, comparisonFn);
