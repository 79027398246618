import React, { useState, useEffect, useRef } from 'react';

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Tag } from 'primereact/tag';


import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';

import HeatMapGrid from 'react-heatmap-grid';
import { parseISO, format, eachDayOfInterval, startOfDay, endOfDay, getHours } from 'date-fns';


import { StatisticsService } from '../../service/StatisticsService';
import { TerminalsService } from '../../service/TerminalService';
import { addMonths } from '../../helper/DateHelper';

const SensorMonitoringMonthly = () => {

    let emptyRow = {
        'id': null,
        'name': ""
    };

    const [gridData, setGridData] = useState(null);
    const [gridDialog, setGridDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [selectedRow, setSelectedRow] = useState(emptyRow);
    const [multiSortMeta, setMultiSortMeta] = useState([{ field: 'id', order: 1 }]);

    const toast = useRef(null);
    const dt = useRef(null);

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(500);
    const [firstPage, setFirstPage] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(null);
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const [startDate, setStartDate] = useState(addMonths(new Date(), 0));
    const [endDate, setEndDate] = useState(new Date());

    const [xData, setXData] = useState(null);
    const [yData, setYData] = useState(null);
    const [heatmapData, setHeatmapData] = useState(null);

    const [terminalCode, setTerminalCode] = useState("1");
    const [terminalData, setTerminalData] = useState([]);

    const initFilters = {
        'terminalcode': { value: 1, matchMode: FilterMatchMode.CONTAINS },
        'month': { value: startDate.getMonth()+1, matchMode: FilterMatchMode.EQUALS },
        'year': { value: startDate.getFullYear(), matchMode: FilterMatchMode.EQUALS },
    }

    const paginatorLeft = "";
    const paginatorRight = "";

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: pageSize,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: initFilters
    });

    useEffect(() => {
        setLoading(true);
        const terminalService = new TerminalsService();
        terminalService.getTerminalsList().then(data => {
            setTerminalData(data?.filter(x => x.terminaltype === 'monitoring'));
            setLoading(false);
        })
    }, []);


    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = ()  => {
        setLoading(true);
        const statisticsService = new StatisticsService();
        statisticsService.getMonitoringPeaks(lazyParams.page + 1, lazyParams.rows, lazyParams.filters, 
            { field: lazyParams.sortField, order: lazyParams.sortOrder }).then(data => {
                if("status" in data && data.status === 401) {
                    //props.logout();
                }
                //console.log("Statistics list", data);
                setGridData(data.data);
                formatHeatMapData(data.data);
                setPageNumber(data.pageNumber);
                setPageSize(data.pageSize);
                setFirstPage(data.firstPage);
                setLastPage(data.lastPage);
                setTotalPages(data.totalPages);
                setTotalRecords(data.totalRecords);
                setNextPage(data.nextPage);
                setPreviousPage(data.previousPage);
                setLoading(false);
        }).catch(error => {
            console.log("Sensor summary error:", error);
        });
    }

    const formatHeatMapData = (data) => {

        //console.log("Heatmap prepare data" , gridData);

        const timestamps = data.map(entry => parseISO(entry.timestamp));

        // Step 2: Determine the range of dates for the X-axis
        const minDate = startOfDay(Math.min(...timestamps));
        const maxDate = endOfDay(Math.max(...timestamps));
        const days = eachDayOfInterval({ start: minDate, end: maxDate }).map(day =>
          format(day, 'dd')
        );

        // Step 3: Prepare the Y-axis (hours)
        const hours = Array.from({ length: 17 }, (_, i) => (7 + i).toString().padStart(2, '0') + ':00');

        // Step 4: Initialize the heatmap data
        const heatmapData = Array(hours.length).fill(0).map(() =>
        Array(days.length).fill(0)
        );

        // Step 5: Populate the heatmap data
        timestamps.forEach(timestamp => {
        const day = format(timestamp, 'dd');
        const hour = format(timestamp, 'HH:00');

        const dayIndex = days.indexOf(day);
        const hourIndex = hours.indexOf(hour);

        if (dayIndex >= 0 && hourIndex >= 0) {
            heatmapData[hourIndex][dayIndex]++;
        }
        });

        setXData(days);
        setYData(hours);
        setHeatmapData(heatmapData);

    }

    const onTerminalChange = (e) => {
        try {
            setTerminalCode(e.target.value);
            const _params = JSON.parse(JSON.stringify(lazyParams));
            _params.filters["terminalcode"].value = e.target.value;
            setLazyParams(_params); 
        } catch (error) {
            console.log("Error onLocationChange", error);
        }
    }

    const setFilterByStartDate = (datePostedValue) => {        
        try {
            setStartDate(datePostedValue);

            const _params = JSON.parse(JSON.stringify(lazyParams));

            _params.filters.month.value = datePostedValue.getMonth() + 1;
            _params.filters.month.year = datePostedValue.getFullYear();

            setLazyParams(_params);
        } catch (error) {
            console.log("Error setFilterByStartDate", error);
        }
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="field">
                <label htmlFor="range1" className="block">Senzor: </label>
                <Dropdown
                        value={terminalCode}
                        options={terminalData}
                        onChange={(e) => onTerminalChange(e)}
                        optionLabel="name"
                        optionValue="terminalcode"
                        placeholder="Senzor ..."
                    />&nbsp;&nbsp;
                </div>
                <div className="field">
                    <label htmlFor="range1" className="block">Mesec: </label>
                            <Calendar value={startDate} onChange={(e) => setFilterByStartDate(e.value)}  view="month" dateFormat="mm/yy"/>&nbsp;&nbsp;
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <span>
                    {/* <Button label="Export" onClick={() => exportExcel(gridData)} />&nbsp;&nbsp;
                    <Button label="Export all" onClick={() => exportCompleteResults(gridData)} />  */}
                </span>
            </React.Fragment>
        )
    }

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Lista prekida</h5>
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <Toast ref={toast} />                    
                    <div>
                        {
                            heatmapData && xData && yData && 
                            <HeatMapGrid
                                xLabels={xData}
                                yLabels={yData}
                                data={heatmapData}
                                cellHeight='30px'
                                cellWidth='30px'
                                cellStyle={(background, value) => ({
                                background: `rgb(255, ${255 - value * 20}, ${255 - value * 20})`,
                                fontSize: "10px",
                                })}
                                square
                            />                            
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};


export default React.memo(SensorMonitoringMonthly, comparisonFn);