export const MOCK_USER_TYPES = [
    {
        id: 1,
        name: "Administrator"
    },
]

export const MOCK_USER_LIST = [
    {"id":1,"name":"Admin","username":"admin","email":"test@test.com","password":"test","isactive":1,"usertype":1}
]



export const MOCK_DATA_BAD_RESULTS = {
    labels: ['Mart', 'April', 'Maj', 'Jun'],
    datasets: [
        {
            label: 'Pozicije van opsega',
            data: [45, 39, 12, 10],
            fill: false,
            backgroundColor: '#ff4d4d',
            borderColor: '#ff4d4d',
            tension: .4
        }
    ]
}


export const MOCK_DATA_BAD_RESULTS_PERCENT = {
    labels: ['Mart', 'April', 'Maj', 'Jun'],
    datasets: [
        {
            label: 'Pozicije van opsega %',
            data: [2.1, 1.9, 1, 0.8],
            fill: false,
            backgroundColor: '#3385ff',
            borderColor: '#3385ff',
            tension: .4
        }
    ]
}

export const MOCK_DATA_CPK_RESULTS = {
    labels: ['Mart', 'April', 'Maj', 'Jun'],
    datasets: [
        {
            label: 'Cpk prosek',
            data: [2.1, 1.9, 1, 0.8],
            fill: false,
            backgroundColor: '#cc00ff',
            borderColor: '#cc00ff',
            tension: .4
        }
    ]
}

// url: "elitinoxapi.azurewebsites.net",
// port: 80
export const GENERAL_SETTINGS = {
    api:{
		protocol: "https://",
        url: "elitinoxapi.azurewebsites.net",
        port: 443,
		code: "FMj5qSHaE1xH4kKBK9fYwfCOq3i4NWRDfH1KYvjTi4wKAzFupEn3zA=="
    }
};
//FMj5qSHaE1xH4kKBK9fYwfCOq3i4NWRDfH1KYvjTi4wKAzFupEn3zA==


/*export const GENERAL_SETTINGS = {
    api:{
		protocol: "http://",
        url: "127.0.0.1",
        port: 7297,
		code: "JIF53GF6gedDdZz91Q49hCgUdq10o5AJid58wb7MLL2qAzFu-Hje2A=="
    }
};*/

export const DEFAULT_CLIENT_ID = 1;


export const TERMINAL_ACTIVE_TIME = 120;
export const TERMINAL_PAUSE_TIME = 600;

export const STATUS_ACTIVE = 1;
export const STATUS_INACTIVE = 2;

export const FilterMatchISNULL = 'isNull';
export const FilterValueISNULL = 'null';

export const MOCK_MEASUREMENT_LIMIT = 10;

export const TERMINAL_TYPE_LIST = [
    {
      "id": 1,
      "code": "monitoring",
      "name": "Monitoring"
    },
    {
      "id": 2,
      "code": "process_capability",
      "name": "Analiza procesa"
    }
];


export const TERMINAL_TYPE_MONITORING = "monitoring";
export const TERMINAL_TYPE_PROCESS_CAPABILITY = "process_capability";