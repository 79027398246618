import React, { useState, useEffect, useRef } from 'react';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import { FilterMatchMode, FilterOperator } from 'primereact/api';

import { SensorGraphItem } from '../../components/SensorGraphItem';

import { MeasurementService } from '../../service/MeasuremetService';
import { TerminalsService } from '../../service/TerminalService';

import { exportExcel } from '../../helper/FileHelper';
import { addHours, addMonths } from '../../helper/DateHelper';
import { filterDataByTerminalLimits } from '../../helper/MeasurementHelper';

const Measurements = () => {

    const [gridData, setGridData] = useState(null);
    const [terminalGridData, setTerminalGridData] = useState(null);
    const [multiSortMeta, setMultiSortMeta] = useState([{ field: 'timestamp', order: 1 }]);

    const dt = useRef(null);

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(1000);
    const [firstPage, setFirstPage] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(null);
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    // const [startDate, setStartDate] = useState(addHours(new Date(), -7200));
    // const [endDate, setEndDate] = useState(addHours(new Date(), 24));

    const [startDate, setStartDate] = useState(addMonths(new Date(), -1));
    const [endDate, setEndDate] = useState(new Date());

    const [first, setFirts] = useState(0);

    const [startTimestamp, setStartTimestamp] = useState(addHours(new Date(), -7200).getTime());
    const [endTimestamp, setEndTimestamp] = useState(addHours(new Date(), 24).getTime());


    const initFilters = {
        'terminalcode': { value: null, matchMode: FilterMatchMode.EQUALS },
        'value': { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
        'timestamp'  : { operator: FilterOperator.AND, constraints: [
            { value: startDate, matchMode: FilterMatchMode.DATE_AFTER }, 
            { value: endDate, matchMode: FilterMatchMode.DATE_BEFORE }
        ]} 
    }

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: pageSize,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: initFilters
    });

    useEffect(() => {
        setLoading(true);
        const terminalService = new TerminalsService();
        terminalService.getTerminalsList().then(data => {
            setTerminalGridData(data);
            setLoading(false);
        })
    }, [lazyParams]);

    useEffect(() => {
        loadData();
    }, [lazyParams]);



    const loadData = () => {
        setLoading(true);
        const measurementService = new MeasurementService();
        measurementService.getMeasurement(lazyParams.page + 1, lazyParams.rows, lazyParams.filters, 
            { field: lazyParams.sortField, order: lazyParams.sortOrder }).then(data => {
                if("status" in data && data.status === 401) {
                    //props.logout();
                }
                setGridData(data.data);
                setPageNumber(data.pageNumber);
                setPageSize(data.pageSize);
                setFirstPage(data.firstPage);
                setLastPage(data.lastPage);
                setTotalPages(data.totalPages);
                setTotalRecords(data.totalRecords);
                setNextPage(data.nextPage);
                setPreviousPage(data.previousPage);
                setLoading(false);
        }).catch(error => {
            console.log("Measurement error:", error);
        });
    }

    /**
     * Object format {first: 100, rows: 100, page: 1, pageCount: 14}
     * @param {object} event 
     */
    const onPageChange = (event) => {
        console.log("Page change", event);
        setPageSize(event.rows);
        setPageNumber(event.page);
        setTotalPages(event.totalPages);
        setFirts(event.first);
        //const _params = JSON.parse(JSON.stringify(lazyParams));
        //_params.pageSize = event.rows;
        loadLazyParams(event);
    }

    const loadLazyParams = (event) => {
        const _params = JSON.parse(JSON.stringify(lazyParams));
        _params.rows = event.rows;
        _params.page = event.page;
        setLazyParams(_params);
    }

    const exportCompleteResults = () => {
        setLoading(true);
        const measurementService = new MeasurementService();
        console.log(1, totalPages, lazyParams.rows);
        measurementService.exportMeasurements(1, totalPages, lazyParams.rows, lazyParams.filters, 
            { field: lazyParams.sortField, order: lazyParams.sortOrder }).then(data => {
                if("status" in data && data.status === 401) {
                    //props.logout();
                }
                exportExcel(data);
                setLoading(false);
        }).catch(error => {
            console.log("ExportCompleteResults error:", error);
        });
    }

    const setFilterByStartDate = (datePostedValue) => {        
        try {
            setStartDate(datePostedValue);
            setStartTimestamp(datePostedValue.getTime());
            const _params = JSON.parse(JSON.stringify(lazyParams));
            const result = _params.filters.timestamp.constraints.map(el => el.matchMode === FilterMatchMode.DATE_AFTER 
                ? {...el, value:datePostedValue} : el);
            _params.filters.timestamp.constraints = result;
            setLazyParams(_params);
        } catch (error) {
            console.log("Error setFilterByStartDate", error);
        }
    }

    const setFilterByEndDate = (datePostedValue) => {
        try {
            setEndDate(datePostedValue);
            setEndTimestamp(datePostedValue.getTime());
            const _params = JSON.parse(JSON.stringify(lazyParams));
            const result = _params.filters.timestamp.constraints.map(el => el.matchMode === FilterMatchMode.DATE_BEFORE 
                ? {...el, value:datePostedValue} : el);
            _params.filters.timestamp.constraints = result;
            setLazyParams(_params);
        } catch (error) {
            console.log("Error setFilterByEndDate", error);
        }
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="field">
                    <label htmlFor="range1" className="block">Od datuma: </label>
                            <Calendar value={startDate} onChange={(e) => setFilterByStartDate(e.value)} dateFormat="dd.mm.yy"/>&nbsp;&nbsp;
                </div>
                <div className="field">
                    <label htmlFor="range1" className="block">Do datuma: </label>
                        <Calendar value={endDate} onChange={(e) => setFilterByEndDate(e.value)} dateFormat="dd.mm.yy"/>&nbsp;&nbsp;
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <span>
                    <Button label="Export" onClick={() => exportExcel(gridData)} />&nbsp;&nbsp;
                    <Button label="Export all" onClick={() => exportCompleteResults()} />     
                </span>
            </React.Fragment>
        )
    }
    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Merenja</h5>
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <Paginator first={first}  rows={pageSize} totalRecords={totalRecords} rowsPerPageOptions={[100, 200, 500, 1000]} onPageChange={onPageChange} />

                    {                        
                        terminalGridData?.map((item, index) => {
                            let data = gridData?.filter(x=> x.terminalcode === item.terminalcode);
                            data = data && filterDataByTerminalLimits(data, item);
                            return data && <SensorGraphItem data={data} title={item.name} color={item.color}/>
                        })
                    }
                    {/* <Chart type="line" data={chartData} options={chartOptions} /> */}
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};


export default React.memo(Measurements, comparisonFn);