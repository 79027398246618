import React, { useState, useEffect, useRef } from 'react';
import { Tag } from 'primereact/tag';
import { addHours, formatDate, formatMinutes, getMinutesDifference } from '../helper/DateHelper';
import { MeasurementService } from '../service/MeasuremetService';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { TERMINAL_ACTIVE_TIME, TERMINAL_PAUSE_TIME } from '../constants/constants';

export const SensorItem = (props) => {

    const [senzors, setSenzors] = useState([]);
    const [senzorsData, setSenzorsData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(1);

    const [startDate, setStartDate] = useState(addHours(new Date(), -720));
    const [endDate, setEndDate] = useState(addHours(new Date(), 12));

    const initFilters = {
        'terminalcode': { value: props.id, matchMode: FilterMatchMode.CONTAINS } , 
        //'value': { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
        //'timestamp'  : { operator: FilterOperator.AND, constraints: [
        //    { value: startDate, matchMode: FilterMatchMode.DATE_AFTER }, 
        //    { value: endDate, matchMode: FilterMatchMode.DATE_BEFORE }
        //]} 
    }

    const paginatorLeft = "";
    const paginatorRight = "";

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: pageSize,
        page: 0,
        sortField: "timestamp",
        sortOrder: -1,
        filters: initFilters
    });

    useEffect(() => {
        loadSenzorData();
    }, [lazyParams]);


    const loadSenzorData = () => {

        setLoading(true);
        const measurementService = new MeasurementService();
        measurementService.getSensorStatus(lazyParams.filters, 
            { field: lazyParams.sortField, order: lazyParams.sortOrder }).then(data => {
                if("status" in data && data.status === 401) {
                    //props.logout();
                }
                console.log("Senzor item ", data);
                setSenzorsData(data?.[0]);

                setLoading(false);
        }).catch(error => {
            console.log("Measurement error:", error);
        });
    }

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>{props.name}</h5>
                    {/* <i className="pi pi-mobile text-blue-500" style={{ fontSize: '36' }}></i> */}
                    <span className="p-6 shadow-2 mb-3 inline-block" style={{ borderRadius: '10px' }}>
                        <i className="pi pi-mobile text-8xl text-blue-500" style={{ fontSize: '2.5rem' }}></i>
                    </span>
                    <div style={{ fontSize: '1.5rem', padding: 10, }}>Status: </div>
                    <div style={{ padding: 5}}>
                        {
                            getMinutesDifference(senzorsData?.timestamp) !== undefined && 
                            getMinutesDifference(senzorsData?.timestamp) > TERMINAL_PAUSE_TIME &&
                            <Tag value={"Neaktivan"} severity={"danger"} style={{ fontSize: '1rem' }}/>
                        }
                        {
                            getMinutesDifference(senzorsData?.timestamp) !== undefined && 
                            getMinutesDifference(senzorsData?.timestamp) <= TERMINAL_PAUSE_TIME &&
                            getMinutesDifference(senzorsData?.timestamp) > TERMINAL_ACTIVE_TIME &&
                            <Tag value={"Bez izmene"} severity={"warning"} style={{ fontSize: '1rem' }}/>
                        }
                        {
                            getMinutesDifference(senzorsData?.timestamp) !== undefined && 
                            getMinutesDifference(senzorsData?.timestamp) < TERMINAL_ACTIVE_TIME &&
                            <Tag value={"Aktivan"} severity={"success"} style={{ fontSize: '1rem' }}/>
                        }

                               {/*  <Tag value={"Aktivan"} severity={"success"} style={{ fontSize: '1rem' }}/> */}
                    </div>
                    <div style={{padding: 5}}>Poslednja izmena: {formatDate(senzorsData?.timestamp)}</div>
                    {/* <div style={{padding: 5}}>{formatDate(senzorsData?.timestamp)}</div> */}
                    <div style={{padding: 5}}>Vreme od poslenje izmene: {formatMinutes(getMinutesDifference(senzorsData?.timestamp))}</div>
                    {/* <div style={{padding: 5}}>{formatMinutes(getMinutesDifference(senzorsData?.timestamp))}</div>   */}                  
                </div>
            </div>
        </div>
    );
}

// const comparisonFn = function (prevProps, nextProps) {
//     return prevProps.location.pathname === nextProps.location.pathname;
// };

// export default React.memo(SensorItem, comparisonFn);