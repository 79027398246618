import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Route, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { combineReducers } from 'redux';

import { userKeyReducer } from './reducers/userKeyReducer';

import { AppTopbar } from './AppTopbar';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppConfig } from './AppConfig';

import Dashboard from './pages/Dashboard';

import Measurements from './pages/overview/Measurements';
import MeasurementsList from './pages/overview/MeasurementsList';
import SensorStatus from './pages/overview/SensorStatus';

import SensorSummaryMonthly from './pages/statistics/SensorSummaryMonthly';
import SensorMonitoringMonthly from './pages/statistics/SensorMonitoringMonthly';

import Users from './pages/settings/Users';
import Sensors from './pages/settings/Sensors';


import Login from './pages/Login';

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.scss';
import "./styles.css";


const rootReducer = combineReducers({
    userKey: userKeyReducer,
  });

const store = createStore(rootReducer);
//const store = configureStore(rootReducer);

const App = () => {
    //const [LoggedInState, setLoggedInState] = useState(!!localStorage.getItem('token'));
    const [LoggedInState, setLoggedInState] = useState(true);
    const [state, setState] = useState({});
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('username'));

    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
        console.log("Store", store);
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        localStorage.removeItem('user_name');
        localStorage.removeItem('token');
        localStorage.removeItem('expires_in');

        setIsLoggedIn(false);
        location.href = '/';
        
        
        //event.preventDefault();
    }

    const logoutUser = () => {
        localStorage.removeItem('user_name');
        localStorage.removeItem('token');
        localStorage.removeItem('expires_in');
        setLoggedInState(false);
        location.href = '/';
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }

    const menu = [
        {
            label: 'Početna',
            items: [{
                label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/'
            }]
        },
        {
            label: 'Pregled', icon: 'pi pi-fw pi-clone',
            items: [
                { label: 'Merenja', icon: 'pi pi-fw pi-chart-bar', to: '/measurements' },
                { label: 'Statusi senzora', icon: 'pi pi-fw pi-circle-fill', to: '/status' },
                { label: 'Lista merenja', icon: 'pi pi-fw pi-list', to: '/list' }
            ]
        },
        {
            label: 'Statistika', icon: 'pi pi-fw pi-clone',
            items: [
                { label: 'Mesečni izveštaj', icon: 'pi pi-fw pi-calendar', to: '/summarymonthly' },
                { label: 'Mesečni pregled', icon: 'pi pi-fw pi-chart-line', to: '/monitoringmonthly' },
            ]
        },
        {
            label: 'Podešavanja', icon: 'pi pi-fw pi-clone',
            items: [
                { label: 'Korisnici', icon: 'pi pi-fw pi-user-edit', to: '/users' },
                { label: 'Senzori', icon: 'pi pi-fw pi-tablet', to: '/sensors' }
            ]
        }
    ];

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });

    return (
        <Provider store={store}>
        <div className="App">            
            {!LoggedInState ? (
                <Login setLoggedInState={setLoggedInState} setState={setState}></Login>
            ) : null}
        </div>
        {
            LoggedInState && 
            <div className={wrapperClass} onClick={onWrapperClick}>
                <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                    mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

                <div className="layout-sidebar" onClick={onSidebarClick}>
                    <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                </div>

                <div className="layout-main-container">
                    <div className="layout-main">
                        <Route path="/" exact render={() => <Dashboard colorMode={layoutColorMode} location={location} logout={logoutUser} />} />
                        
                        <Route path="/measurements" component={Measurements} />
                        <Route path="/status" component={SensorStatus} />
                        <Route path="/list" component={MeasurementsList} />
                        <Route path="/summarymonthly" component={SensorSummaryMonthly} />
                        <Route path="/monitoringmonthly" component={SensorMonitoringMonthly} />

                        <Route path="/users" component={Users} />
                        <Route path="/sensors" component={Sensors} />
                    </div>

                    <AppFooter layoutColorMode={layoutColorMode} />
                </div>

                <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                    layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

                <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                    <div className="layout-mask p-component-overlay"></div>
                </CSSTransition>

            </div>            
        }

        </Provider>
    );

}

export default App;
