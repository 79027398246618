import React, { useState, useEffect, useRef } from 'react';

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import { Password } from 'primereact/password';
import { MultiSelect } from 'primereact/multiselect';
import { ListBox } from 'primereact/listbox';

import { findIndexByIdV2, getMaxId } from '../../helper/DataSetHelper';
import { UsersService } from '../../service/UsersService';


import { MOCK_USER_LIST, MOCK_USER_TYPES } from '../../constants/constants';
import { onInputChange } from '../../helper/DialogEventHelper';
import { statusBodyTemplate } from '../../components/DataColumnTemplate';

const Users = () => {

    let emptyRow = {
        'id': null,
        "username": "",
        "password": "",
        "name": "",
        "email": null,
        "status": 1
    };
    const [gridData, setGridData] = useState(null);
    const [gridDialog, setGridDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [selectedRow, setSelectedRow] = useState(emptyRow);
    const [multiSortMeta, setMultiSortMeta] = useState([{ field: 'id', order: 1 }]);

    const [terminalsData, setTerminalsData] = useState([]);

    const [filters, setFilters] = useState({
        'id': { value: null, matchMode: FilterMatchMode.EQUALS },
        'usertype': { value: null, matchMode: FilterMatchMode.EQUALS },
        'name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'username': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'email': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const toast = useRef(null);
    const dt = useRef(null);

    const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     setLoading(true);
    //     const userService = new UsersService();
    //     userService.getUsersList().then(data => {
    //         setGridData(data);
    //         setLoading(false);
    //     }).catch(res => {
    //         console.log("Users:", res);
    //     })
    // }, []);



    const hideDialog = () => {
        setSubmitted(false);
        setGridDialog(false);
    }

    const newRow = () => {
        setSelectedRow(emptyRow);
        setSubmitted(false);
        setGridDialog(true);
    }

    const editRow = () => {
        setGridDialog(true);
    }

    const saveRow = () => {
        setSubmitted(true);
        //const usersService = new UsersService();
        let _rows = [...gridData];
        let _selectedRow = { ...selectedRow };
        var validationMsg = ""; //validateFields(_selectedUsers);

        if (validationMsg === "") {
            // if (_selectedRow.id) {
            //     var excludeCodesObj = _rows.filter(obj => {
            //         return obj.id !== _selectedRow.id
            //     })

            //     let isCodeAlreadyExists = false; //checkIfCodeAlreadyExists(excludeCodesObj, _selectedUsers)

            //     if (isCodeAlreadyExists) {
            //         toast.current.show({ severity: 'error', summary: 'Error', detail: 'User already exists', life: 3000 });
            //         return    
            //     }

            //     const index = findIndexByIdV2(selectedRow.id, "id", _rows);

            //     _rows[index] = _selectedRow;

            //     usersService.updateUser(_selectedRow).then(data => {
            //         console.log(data);
            //     });      

            //     toast.current.show({ severity: 'success', summary: 'Success', detail: 'User changed', life: 3000 });                
            // }
            // else {
            //     // check if this code already exists
            //     let isCodeAlreadyExists = false; // checkIfCodeAlreadyExists(_users, _selectedUsers)

            //     if (isCodeAlreadyExists) {
            //         toast.current.show({ severity: 'error', summary: 'Error', detail: 'User already exists', life: 3000 });
            //         return
            //     }

            //     _selectedRow.id = Math.max(..._rows.map(o => o.id)) + 1;
  
            //     usersService.createUser(_selectedRow).then(res => {
            //         if(res.status) {
            //             _selectedRow.id = res.data.id;   
            //             return true;
            //         }
            //         return false;
            //     });      

            //     _rows.push(_selectedRow);     

            //     toast.current.show({ severity: 'success', summary: 'Success', detail: 'User created', life: 3000 });                
            // }

            setGridData(_rows);
            setGridDialog(false);
            setSelectedRow(emptyRow);

        } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: validationMsg, life: 3000 });
            return;
        }
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const onUsrTypeChange = (e) => {
        let _row = { ...selectedRow };
        _row[`ucUserType`] = e.target.value;
        _row[`ucUserTypeName`] = MOCK_USER_TYPES.find(x=>x.id === e.target.value)?.name;
        setSelectedRow(_row);
    }


    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                {/* <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Keyword Search" />
                </span> */}
            </div>
        )
    }

    const itemUserTypeTemplate = (row) => {
        return (
            <div className="flex align-items-center">
                <div>{MOCK_USER_TYPES.find(x=>x.id === row.ucUserType)?.name}</div>
            </div>
        );
    }


    const gridDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Confirm" icon="pi pi-check" className="p-button-text" onClick={saveRow} />
        </>
    );


    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <span>
                    <Button label="Unos" onClick={newRow} />&nbsp;&nbsp;
                    <Button label="Izmena" onClick={editRow} />&nbsp;&nbsp;
                    <Button label="Eksport" onClick={exportCSV} />
                </span>
            </React.Fragment>
        )
    }

    const header = renderHeader();

    return (
        <div className="grid">
            <div className="col-9">
                <div className="card">
                    <h5>Korisnici</h5>
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>
                    <DataTable
                        ref={dt}
                        value={MOCK_USER_LIST}
                        sortMode="multiple"
                        removableSort
                        multiSortMeta={multiSortMeta}
                        onSort={(e) => setMultiSortMeta(e.multiSortMeta)}
                        responsiveLayout="scroll"
                        filters={filters}
                        filterDisplay="row"
                        globalFilterFields={['id', 'name']}
                        header={header}
                        loading={loading}
                        selectionMode="single"
                        selection={selectedRow}
                        onSelectionChange={e => setSelectedRow(e.value)}
                    >
                        <Column field="id" header="ID" filter filterPlaceholder="Pretraži po šifri" style={{ minWidth: '12rem', width: '90px' }} sortable></Column>
                        <Column field="name" header="Ime" filter filterPlaceholder="Pretraži po imenu .."  sortable></Column>
                        <Column field="username" header="Korisničko ime" filter filterPlaceholder="Pretraži po korisničkom imenu .." sortable></Column>
                        <Column field="email" header="Email" filter filterPlaceholder="Pretraži po email-u .." sortable></Column>
                        <Column field="isactive" header="Status"  body={statusBodyTemplate}  sortable></Column>
                    </DataTable>
                    <Dialog visible={gridDialog} style={{ width: '450px' }} header="Unos/Izmena" modal className="p-fluid" footer={gridDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="description">ID: </label>
                            <span>{selectedRow.id}</span>
                        </div>
                        <div className="field">
                            <label htmlFor="description">Ime: </label>
                            <InputText id="name" value={selectedRow.name} onChange={(e) => onInputChange(e, 'name', selectedRow, setSelectedRow)} required autoFocus className={classNames({ 'p-invalid': submitted && !selectedRow.name })} />
                            {submitted && !selectedRow.name && <small className="p-invalid">Ime korisnika je obavezno.</small>}
                        </div>                        
                        <div className="field">
                            <label htmlFor="description">Korisničko ime: </label>
                            <InputText id="ucTitle" value={selectedRow.username} onChange={(e) => onInputChange(e, 'username', selectedRow, setSelectedRow)} required autoFocus className={classNames({ 'p-invalid': submitted && !selectedRow.username })} />
                            {submitted && !selectedRow.username && <small className="p-invalid">Korisničko ime je obavezno.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="description">Šifra: </label>
                            <Password  id="password" value={selectedRow.password} onChange={(e) => onInputChange(e, 'password', selectedRow, setSelectedRow)} required autoFocus className={classNames({ 'p-invalid': submitted && !selectedRow.password })} />
                            {submitted && !selectedRow.password && <small className="p-invalid">Šifra je obavezna.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="description">Email: </label>
                            <InputText id="email" value={selectedRow.email} onChange={(e) => onInputChange(e, 'email', selectedRow, setSelectedRow)} required autoFocus className={classNames({ 'p-invalid': submitted && !selectedRow.email })} />
                            {submitted && !selectedRow.email && <small className="p-invalid">Email je obavezan.</small>}
                        </div>
                        <div className="field">
                                <label htmlFor="category4" style={{ fontWeight: 'bold' }}>Tip korisnika:</label>
                                <Dropdown
                                    value={selectedRow.ucUserType}
                                    options={MOCK_USER_TYPES}
                                    onChange={(e) => onUsrTypeChange(e, 'ucUserType')}
                                    optionLabel="name"
                                    optionValue="id"
                                    placeholder="Tip korisnika ..."
                                />
                            </div>

                    </Dialog>

                </div>
            </div>
        </div>

    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Users, comparisonFn);
