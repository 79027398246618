import React, { useState, useEffect, useRef } from 'react';
import { Chart } from 'primereact/chart';
import { formatDateGraph } from '../helper/DateHelper';


export const SensorGraphItem = ({data, color, title }) => {

    //const [gridData, setGridData] = useState(data);

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    const documentStyle = getComputedStyle(document.documentElement);

    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    

    const options = {
        stacked: false,
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
            legend: {
                labels: {
                    color: textColor
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            },
            y: {
                type: 'linear',
                display: true,
                position: 'right',
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            },
            /* y1: {
                type: 'linear',
                display: true,
                position: 'right',
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    drawOnChartArea: false,
                    color: surfaceBorder
                }
            } */
        }
    };

    useEffect(() => {
        //console.log("Props change", data);
        let data2 = {
            labels : data?.map(x => formatDateGraph(x.timestamp)).reverse(),
            datasets: [
                {
                    label: title,
                    fill: false,
                    borderColor: documentStyle.getPropertyValue(color),
                    yAxisID: 'y',
                    tension: 0.4,
                    data: data?.map(x => x.value).reverse()
                }
            ]
        }

        /* const data = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    label: 'Senzor 1',
                    fill: false,
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    yAxisID: 'y',
                    tension: 0.4,
                    data: [65, 59, 80, 81, 56, 55, 10]
                },
                {
                    label: 'Senzor 2',
                    fill: false,
                    borderColor: documentStyle.getPropertyValue('--green-500'),
                    yAxisID: 'y1',
                    tension: 0.4,
                    data: [28, 48, 40, 33, 86, 27, 90]
                }
            ]
        }; */


        setChartData(data2);
        setChartOptions(options);
    }, [data])


    return (
        <div>
            <Chart type="line" data={chartData} options={chartOptions} /><br/>
        </div>
    )


}
