import React, { useState, useEffect, useRef } from 'react';

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';

import { onInputChange, onLimitsChange, onSelectChange } from '../../helper/DialogEventHelper';
import { activeBodyTemplate, listBodyTemplate } from '../../components/DataColumnTemplate';
import { TerminalsService } from '../../service/TerminalService';
import { findIndexByIdV2, generate_uuidv4 } from '../../helper/DataSetHelper';
import { filterListTemplate } from '../../helper/FilterHelper';
import { TERMINAL_TYPE_LIST, TERMINAL_TYPE_PROCESS_CAPABILITY } from '../../constants/constants';

const Sensors = () => {

    let emptyRow = {
        'id': null,
        'name': "",
        "terminalcode": 0,
        "code": null,
        "address": "",
        "isactive": 1,
        "color": "--orange-500",
        "discriminator": "cTerminal",
        "goodlimitslow" : null,
        "goodlimitshigh" : null,
        "ignorelimitslow": null,
        "ignorelimitshigh":null,
        "errorlimitslow": null,
        "errorlimitshigh": null,
        "terminaltype": TERMINAL_TYPE_PROCESS_CAPABILITY
    };
    const [gridData, setGridData] = useState(null);
    const [gridDialog, setGridDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [selectedRow, setSelectedRow] = useState(emptyRow);
    const [multiSortMeta, setMultiSortMeta] = useState([{ field: 'id', order: 1 }]);

    const [filters, setFilters] = useState({
        'id': { value: null, matchMode: FilterMatchMode.EQUALS },
        'name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'terminaltype': { value: null, matchMode: FilterMatchMode.CONTAINS },        
        'terminalcode': { value: null, matchMode: FilterMatchMode.EQUALS },
        'isactive': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const toast = useRef(null);
    const dt = useRef(null);

    const [loading, setLoading] = useState(false);
    const hideDialog = () => {
        setSubmitted(false);
        setGridDialog(false);
    }

    
    useEffect(() => {
        setLoading(true);
        const terminalService = new TerminalsService();
        terminalService.getTerminalsList().then(data => {
            setGridData(data);
            setLoading(false);
        })
    }, []);


    const newRow = () => {
        setSelectedRow(emptyRow);
        setSubmitted(false);
        setGridDialog(true);
    }

    const editRow = () => {
        setGridDialog(true);
    }

    const saveRow = () => {
        setSubmitted(true);
        const terminalService = new TerminalsService();

        if (selectedRow.name.trim()) {
            let _rows = [...gridData];
            let _selectedRow = { ...selectedRow };
            if (_selectedRow.id) {
                const index = findIndexByIdV2(_selectedRow.id, "id", _rows);

                _rows[index] = _selectedRow;
                terminalService.updateTerminal(_selectedRow).then(data => {
                    console.log(data);
                });       

                toast.current.show({ severity: 'success', summary: '', detail: 'Terminal izmenjen', life: 3000 });                
            }
            else {
                _selectedRow.id = generate_uuidv4();
                var result = terminalService.createTerminal(_selectedRow).then(res => {

                    if(res.status) {
                       _selectedRow.id = res.data.id; 

                        return true;
                    }
                    
                    return false;
                }); 
                result ? toast.current.show({ severity: 'success', summary: '', detail: 'Senzor uspešno dodat', life: 3000 }) : 
                    toast.current.show({ severity: 'warning', summary: '', detail: 'Greška', life: 3000 });  
                _rows.push(_selectedRow);     

            }

            setGridData(_rows);
            setGridDialog(false);
            setSelectedRow(emptyRow);
        }
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">

            </div>
        )
    }

    const gridDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Confirm" icon="pi pi-check" className="p-button-text" onClick={saveRow} />
        </>
    );


    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <span>
                    <Button label="New" onClick={newRow} />&nbsp;&nbsp;
                    <Button label="Edit" onClick={editRow} />&nbsp;&nbsp;
                    <Button label="Export" onClick={exportCSV} /> 
                </span>
            </React.Fragment>
        )
    }

    const header = renderHeader();

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Senzori</h5>
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>
                    <DataTable
                        ref={dt}
                        value={gridData}
                        sortMode="multiple"
                        removableSort
                        multiSortMeta={multiSortMeta}
                        onSort={(e) => setMultiSortMeta(e.multiSortMeta)}
                        responsiveLayout="scroll"
                        filters={filters}
                        filterDisplay="row"
                        globalFilterFields={['id', 'name']}
                        header={header}
                        loading={loading}
                        selectionMode="single"
                        selection={selectedRow}
                        onSelectionChange={e => setSelectedRow(e.value)}
                    >
                        {/* <Column field="id" header="ID" filter filterPlaceholder="Pretraži po id" style={{ minWidth: '12rem' }} sortable></Column> */}
                        
                        <Column field="terminalcode" header="Broj" filter sortable style={{  width: '150px' }}></Column>
                        <Column field="code" header="Kod" filter sortable style={{  width: '150px' }}></Column>
                        <Column field="name" header="Naziv" filter sortable  ></Column>
                        <Column field="terminaltype" header="Tip" 
                            body={(rowData, property) => listBodyTemplate(rowData, property, TERMINAL_TYPE_LIST, 'code', 'name')}
                            filterElement={(options) => filterListTemplate(options, TERMINAL_TYPE_LIST, 'code', 'name')}
                            filter sortable></Column> 
                        <Column field="isactive" header="Aktivan" body={activeBodyTemplate} sortable></Column> 
                        <Column field="warninglimitslow" header="LCL" sortable></Column>
                        <Column field="warninglimitshigh" header="UCL" sortable></Column>
                        <Column field="errorlimitslow" header="LSL" sortable></Column>
                        <Column field="errorlimitshigh" header="USL"sortable></Column>
                    </DataTable>
                    <Dialog visible={gridDialog} style={{ width: '1000px' }} header="Details" modal className="p-fluid" footer={gridDialogFooter} onHide={hideDialog}>
                    <div className="formgrid grid">
                        <div className="field col col-6">
                            <div className="field">
                                <label htmlFor="description">ID: </label>
                                <span>{selectedRow.id}</span>
                            </div>
                            <div className="field">
                                <label htmlFor="description">Naziv: </label>
                                <InputText id="name" value={selectedRow.name} onChange={(e) => onInputChange(e, 'name', selectedRow, setSelectedRow )} required autoFocus className={classNames({ 'p-invalid': submitted && !selectedRow.name })} />
                                {submitted && !selectedRow.name && <small className="p-invalid">Naziv je obavezan.</small>}
                            </div>
                            <div className="field">
                                <label htmlFor="description">Broj: </label>
                                <InputText id="terminalcode" value={selectedRow.terminalcode} onChange={(e) => onInputChange(e, 'terminalcode', selectedRow, setSelectedRow )} required autoFocus className={classNames({ 'p-invalid': submitted && !selectedRow.terminalcode })} />
                                {submitted && !selectedRow.terminalcode && <small className="p-invalid">Broj je obavezan.</small>}
                            </div>
                            <div className="field">
                                <label htmlFor="description">Kod: </label>
                                <InputText id="code" value={selectedRow.code} onChange={(e) => onInputChange(e, 'code', selectedRow, setSelectedRow )} required autoFocus className={classNames({ 'p-invalid': submitted && !selectedRow.code })} />
                                {submitted && !selectedRow.code && <small className="p-invalid">Kod je obavezan.</small>}
                            </div>
                            <div className="field">
                                <label htmlFor="description">Boja: </label>
                                <InputText id="color" value={selectedRow.color} onChange={(e) => onInputChange(e, 'color', selectedRow, setSelectedRow )} required autoFocus className={classNames({ 'p-invalid': submitted && !selectedRow.color })} />
                                {submitted && !selectedRow.color && <small className="p-invalid">Boja je obavezna.</small>}
                            </div>
                            <div className="field">
                                <label htmlFor="description">Tip: </label>
                                <Dropdown
                                    value={selectedRow.terminaltype}
                                    options={TERMINAL_TYPE_LIST}
                                    onChange={(e) => onSelectChange(e, 'terminaltype', selectedRow, setSelectedRow)}
                                    optionLabel="name"
                                    optionValue="code"
                                    placeholder="Tip senzora ..."
                                />
                            </div>
                        </div>
                        <div className="field col col-6">
                            <div className="field">
                                <h5>Granice: </h5>
                            </div>
                            {/* <div className="field">
                                <div className="formgrid grid">
                                    <div className="col col-4"><label htmlFor="description">Kontrola (LCL/UCL): </label></div>
                                    <div className="col col-4">
                                    <InputText id="goodlimitslow" value={selectedRow.goodlimitslow} onChange={(e) => onInputChange(e, 'goodlimitslow', selectedRow, setSelectedRow )} />
                                    </div>
                                    <div className="col col-4">
                                    <InputText id="goodlimitshigh" value={selectedRow.goodlimitshigh} onChange={(e) => onInputChange(e, 'goodlimitshigh', selectedRow, setSelectedRow )} />
                                    </div>
                                </div>
                            </div> */}
                            <div className="field">
                                <div className="formgrid grid">
                                    <div className="col col-4"><label htmlFor="description">Kontrola (LCL/UCL): </label></div>
                                    <div className="col col-4">
                                        <InputText id="warninglimitslow" value={selectedRow.warninglimitslow} onChange={(e) => onInputChange(e, 'warninglimitslow', selectedRow, setSelectedRow )} />
                                    </div>
                                    <div className="col col-4">
                                    <InputText id="warninglimitshigh" value={selectedRow.warninglimitshigh} onChange={(e) => onInputChange(e, 'warninglimitshigh', selectedRow, setSelectedRow )} />
                                    </div>
                                </div>
                            </div>
                            <div className="field">
                                <div className="formgrid grid">
                                    <div className="col col-4"><label htmlFor="description">Specifikacija (LSL/USL): </label></div>
                                    <div className="col col-4">
                                    <InputText id="errorlimitslow" value={selectedRow.errorlimitslow} onChange={(e) => onInputChange(e, 'errorlimitslow', selectedRow, setSelectedRow )} />
                                    </div>
                                    <div className="col col-4">
                                    <InputText id="errorlimitshigh" value={selectedRow.errorlimitshigh} onChange={(e) => onInputChange(e, 'errorlimitshigh', selectedRow, setSelectedRow )} />
                                    </div>
                                </div>
                            </div>
                            <div className="field">
                                <div className="formgrid grid">
                                    <div className="col col-4"><label htmlFor="description">Ignorisanje rezultata: </label></div>
                                    <div className="col col-4">
                                    <InputText id="ignorelimitslow" value={selectedRow.ignorelimitslow} onChange={(e) => onInputChange(e, 'ignorelimitslow', selectedRow, setSelectedRow )} />
                                    </div>
                                    <div className="col col-4">
                                    <InputText id="ignorelimitshigh" value={selectedRow.ignorelimitshigh} onChange={(e) => onInputChange(e, 'ignorelimitshigh', selectedRow, setSelectedRow )} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};


export default React.memo(Sensors, comparisonFn);