import axios from 'axios';
import { GENERAL_SETTINGS } from '../constants/constants';

export class MeasurementService {

    /**
     * Merenja sa paginacijom
     * @param {int} page 
     * @param {int} size 
     * @param {object} filters 
     * @param {object} sort 
     * @returns 
     */
    async getMeasurement(page, size, filters, sort) {
        let token = localStorage.getItem("token"); //
        const result = await axios.get(GENERAL_SETTINGS.api.protocol +  GENERAL_SETTINGS.api.url + ':' + GENERAL_SETTINGS.api.port + '/api/MeasurementList', {
            params : {
                pageNumber: page,
                pageSize: size,
                filters: JSON.stringify(filters),
                sort: JSON.stringify(sort),
                code: GENERAL_SETTINGS.api.code
            }
            ,
            headers: { 
                "Authorization": "Bearer " + token
            }
        }).then(res =>
            (res.data === null ? [{}] : res.data)
        ).catch(error =>
            (error.response)
        );
        return result;
    }

    /**
     * Lista merenja bez paginacije
     * @param {int} page 
     * @param {int} size 
     * @param {object} filters 
     * @param {object} sort 
     * @returns 
     */
    async getMeasurementV2(page, size, filters, sort) {
            let token = localStorage.getItem("token"); //
            const result = await axios.get(GENERAL_SETTINGS.api.protocol +  GENERAL_SETTINGS.api.url + ':' + GENERAL_SETTINGS.api.port + '/api/MeasurementListV2', {
                params : {
                    pageNumber: page,
                    pageSize: size,
                    filters: JSON.stringify(filters),
                    sort: JSON.stringify(sort),
                    code: GENERAL_SETTINGS.api.code
                }
                ,
                headers: { 
                    "Authorization": "Bearer " + token
                }
            }).then(res =>
                (res.data === null ? [{}] : res.data)
            ).catch(error =>
                (error.response)
            );
            return result;
        }


    /**
     * 
     * @param {int} pageStart 
     * @param {int} pageEnd 
     * @param {int} size 
     * @param {object} filters 
     * @param {object} sort 
     */
    async exportMeasurements(pageStart, pageEnd, size, filters, sort) {
        let token = localStorage.getItem("token");
        const requests = [];
        for (let i = pageStart; i <= pageEnd; i++) {
          requests.push(
            axios.get(GENERAL_SETTINGS.api.protocol +  GENERAL_SETTINGS.api.url + ':' + GENERAL_SETTINGS.api.port + '/api/MeasurementList', {
                params : {
                    pageNumber: i,
                    pageSize: size,
                    filters: JSON.stringify(filters),
                    sort: JSON.stringify(sort),
                    code: GENERAL_SETTINGS.api.code
                }
                ,
                headers: { 
                    "Authorization": "Bearer " + token
                }
            })
          );
        }
        try {
            // Execute all requests concurrently and wait for all of them to complete
            const responses = await Promise.all(requests);
        
            // Extract data from each response and merge into a single array
            const mergedData = responses.reduce((acc, response) => {
                return acc.concat(response.data.data);
            }, []);
        
            console.log("Merged data: ", mergedData);
            return mergedData;
        } catch (error) {
            console.error('exportMeasurements error:', error);
            return [];
        }
    }

    /**
     * Lista statusa terminala, odnosno poslednja merenja
     * @param {object} filters 
     * @param {object} sort 
     * @returns 
     */
    async getSensorStatus(filters, sort) {
        let token = localStorage.getItem("token"); //
        const result = await axios.get(GENERAL_SETTINGS.api.protocol +  GENERAL_SETTINGS.api.url + ':' + GENERAL_SETTINGS.api.port + '/api/SensorStatus', {
            params : {
                filters: JSON.stringify(filters),
                sort: JSON.stringify(sort),
                code: GENERAL_SETTINGS.api.code
            }
            ,
            headers: { 
                "Authorization": "Bearer " + token
            }
        }).then(res =>
            (res.data === null ? [{}] : res.data)
        ).catch(error =>
            (error.response)
        );
        return result;
    }

}