import axios from 'axios';
import { GENERAL_SETTINGS } from '../constants/constants';


export class StatisticsService {


    /**
     * Mesečni std, cp i cpk
     * @param {int} page 
     * @param {int} size 
     * @param {object} filters 
     * @param {object} sort 
     * @returns object
     */
    async getProcessCapabilitySummary(page, size, filters, sort) {
        let token = localStorage.getItem("token"); //
        const result = await axios.post(GENERAL_SETTINGS.api.protocol +  GENERAL_SETTINGS.api.url + ':' + GENERAL_SETTINGS.api.port + '/api/ProcessCapabilityList', {
                pageNumber: page,
                pageSize: size,
                filters: JSON.stringify(filters),
                sort: JSON.stringify(sort),
                code: GENERAL_SETTINGS.api.code
            }
            ,{
            headers: { 
                "Authorization": "Bearer " + token
            }
        }).then(res =>
            (res.data === null ? [{}] : res.data)
        ).catch(error =>
            (error.response)
        );
        return result;
    }


    /**
     * Monitoring lista ekstrema i prekida
     * @param {int} page 
     * @param {int} size 
     * @param {object} filters 
     * @param {object} sort 
     * @returns object
     */
    async getMonitoringPeaks(page, size, filters, sort) {
        let token = localStorage.getItem("token"); //
        const result = await axios.post(GENERAL_SETTINGS.api.protocol +  GENERAL_SETTINGS.api.url + ':' + GENERAL_SETTINGS.api.port + '/api/MonitoringPeaksList', {
                pageNumber: page,
                pageSize: size,
                filters: JSON.stringify(filters),
                sort: JSON.stringify(sort),
                code: GENERAL_SETTINGS.api.code
            }
            ,{
            headers: { 
                "Authorization": "Bearer " + token
            }
        }).then(res =>
            (res.data === null ? [{}] : res.data)
        ).catch(error =>
            (error.response)
        );
        return result;
    }
}
