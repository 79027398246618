
import { Tag } from 'primereact/tag';
import { MOCK_MEASUREMENT_LIMIT, STATUS_ACTIVE, STATUS_INACTIVE } from '../constants/constants';

export const activeBodyTemplate = (rowData, property) => {
    if(rowData[property.field]) {
        return <Tag value={"Da"} severity={"success"} />;
    }
    return <Tag value={"Ne"} severity={"warning"} />;
};


export const statusBodyTemplate = (rowData, property) => {
    if(rowData[property.field] === STATUS_ACTIVE) {
        return <Tag value={"Aktivan"} severity={"success"} />;
    }
    if(rowData[property.field] === STATUS_INACTIVE) {
        return <Tag value={"Ne"} severity={"warning"} />;
    }
};

export const measurementBodyTemplate = (rowData, property) => {
    if(rowData[property.field] < MOCK_MEASUREMENT_LIMIT) {
        return <Tag value={"U granicama"} severity={"success"} />;
    } else {
        return <Tag value={"Van opsega"} severity={"warning"} />;
    }
};

/**
 * Prikaz vrednosti polja na osnovu ključa iz liste
 * (npr. relaciona tabela koja je već učitana u drugi objekat i selekcija iz te tabele na osnovu ključa)
 * @param {object} rowData 
 * @param {object} property 
 * @param {object} list 
 * @param {string} key 
 * @param {string} field 
 * @returns object
 */
export const listBodyTemplate = (rowData, property, list, key, field) => {
    try {
        return list.find(x=> x[key] === rowData[property.field])[field];
    }
    catch {
        return null;
    }
    
}