import axios from 'axios';
import { GENERAL_SETTINGS } from '../constants/constants';

export class TerminalsService {

    /**
     * Terminal list
     * @returns object
     */
    async getTerminalsList() {
        let token = localStorage.getItem("token");
        const result = await axios.get(GENERAL_SETTINGS.api.protocol +  GENERAL_SETTINGS.api.url + ':' + GENERAL_SETTINGS.api.port + '/api/TerminalsList', {
            params : {
                code: GENERAL_SETTINGS.api.code
            }
            ,
            headers: { 
                "Authorization": "Bearer " + token
            }
        }).then(res =>
            (res.data === null ? [{}] : res.data)
        ).catch(error => {
            console.log("Error getTerminalList");
            console.log(error.response);
            return [];
        }
        );
        return result;
    }


    /**
     * 
     * @param {object} data 
     * @returns 
     */
    async createTerminal(data) {
        const result = await axios.post(GENERAL_SETTINGS.api.protocol +  GENERAL_SETTINGS.api.url + ':' + GENERAL_SETTINGS.api.port + '/api/TerminalsInsert', data, {
                code: GENERAL_SETTINGS.api.code
        }).then(res => {
            return {
                status: (res.status === 201),
                data: (res.data === null ? [{}] : res.data)
            }
        }
        );
        return result;

    }

    /**
     * 
     * @param {object} data 
     * @returns 
     */
    async updateTerminal(data) {
        const result = await axios.put(GENERAL_SETTINGS.api.protocol +  
            GENERAL_SETTINGS.api.url + ':' + 
            GENERAL_SETTINGS.api.port + '/api/TerminalsUpdate?code=' + GENERAL_SETTINGS.api.code, data, {
            headers : {
                //code: "pB6PS07CrsAOmBJgullmTJaQLw8_WvgXPy3bK1E5afnFAzFu4KlH4A==" //GENERAL_SETTINGS.api.code
            }
        }).then(res => {
            return (res.status === 204)
        });
        return result;
    }

}